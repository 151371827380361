<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>

    <a-card>
      <detail-list title="用戶信息" style="width: 100%">
        <detail-list-item term="手機號" style="width: 100%">{{ form.mobile }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="昵稱" style="width: 100%">{{ form.nickname }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="性别" style="width: 100%">{{ form.sex == 0 ? '保密': form.sex == 1 ? '男' : '女' }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="生日" style="width: 100%">{{ form.birthday }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="个性签名" style="width: 100%">{{ form.userSign }}</detail-list-item>
      </detail-list>

      <a-divider style="margin-bottom: 32px"/>

    </a-card>
  </a-drawer>
</template>

<script>
import { getUser } from '@/api/user/user'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item;

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    DetailList,
    DetailListItem
  },
  data () {
    return {
      submitLoading: false,
      withdrawType: null,
      showVer: false,
      formTitle: '',
      // 表单参数
      form: {
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    changeStatus(status){
      if(status == 1){
        this.showVer = false;
      }
      if(status == 2){
        this.showVer = true;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    reset () {
      this.formType = 1
      this.form = {
      }
    },
    /** 修改按钮操作 */
    show (userId) {
      this.reset()
      this.formType = 2
      getUser({"id":userId}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '用戶信息'
      })
    },
  }
}
</script>
<style>
.ant-divider-horizontal {
  display: none;
}
</style>