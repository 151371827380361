import request from '@/utils/request'


// 查询行政区划列表
export function listArea(query) {
  return request({
    url: '/platform/area/list',
    method: 'get',
    params: query
  })
}

// 查询行政区划分页
export function pageArea(query) {
  return request({
    url: '/platform/area/page',
    method: 'get',
    params: query
  })
}

// 查询行政区划分页
export function cancelAllot(query) {
  return request({
    url: '/platform/area/cancelAllot',
    method: 'get',
    params: query
  })
}


// 查询行政区划详细
export function getArea(data) {
  return request({
    url: '/platform/area/detail',
    method: 'get',
    params: data
  })
}

// 新增行政区划
export function addArea(data) {
  return request({
    url: '/platform/area/add',
    method: 'post',
    data: data
  })
}

// 修改行政区划
export function bindMobile(data) {
  return request({
    url: '/platform/area/bindMobile',
    method: 'post',
    data: data
  })
}

// 删除行政区划
export function delArea(data) {
  return request({
    url: '/platform/area/delete',
    method: 'post',
    data: data
  })
}
